import { Howl } from 'howler'

export class HowlPlayer
{
  private howl!: Howl;

  private currentTime: number = 0;
  private duration: number = 0;
  private playing: boolean = false;
  private error: boolean = false;

  private playingId: number | null = null;
  private timerId: NodeJS.Timer | null = null;

  constructor(path: string) {
    this.howl = new Howl({
        src: [path],
        html5: true,
    });
  }

  /**
   * reactiveオブジェクトにした後に実行しないと外からパラメータをwatchした時に検知してくれないので別途実行してる
   */
  public setup(): void {
    this.howl.on('load', () => {
      this.duration = this.howl.duration();
    });
    this.howl.on('end', () => {
      this.clearInterval();
      this.seek(this.duration);
    });
    this.howl.on('loaderror', () => {
      this.error = true;
    });
  }

  public play(): void {
    if (this.playingId) {
      this.howl.play(this.playingId);
    } else {
      this.playingId = this.howl.play();
    }

    this.setInterval();
  }

  public pause(): void {
    this.howl.pause();
    this.clearInterval();
  }

  public rate(_rate: number): void {
    this.howl.rate(_rate);

    if (this.isPlaying()) {
      this.clearInterval();
      this.setInterval();
    }
  }

  public seek(time: number): void {
    this.currentTime = time;
    this.howl.seek(this.currentTime);
  }

  public forward(): void {
    const now = this.howl.seek();
    this.seek(Math.min(now + 15, this.duration));
  }

  public back(): void {
    const now = this.howl.seek();
    this.seek(Math.max(now - 15, 0));
  }

  public getCurrentTime(): number {
    return this.currentTime;
  }

  public getDuration(): number {
    return this.duration;
  }

  public getInstance(): Howl {
    return this.howl;
  }

  public isPlaying(): boolean {
    return this.playing;
  }

  public isError(): boolean {
    return this.error;
  }

  public unload(): void {
    this.howl.unload();
  }

  private setInterval(): void {
    if (this.timerId !== null) {
      return;
    }

    this.timerId = setInterval(() => {
      this.currentTime = this.howl.seek();
    }, 50); // 0.05s

    this.playing = true;
  }

  private clearInterval(): void {
    if (this.timerId === null) {
      return;
    }

    clearInterval(this.timerId);
    this.timerId = null;
    this.playing = false;
  }
}
